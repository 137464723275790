import "./App.css";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { applyMiddleware, compose, createStore } from "redux";
import axios from "axios";
import thunk from "redux-thunk";
import axiosMiddleware from "redux-axios-middleware";
import reducers from "./reducers";
import { httpHandleError, getToken } from "./utils/helper";
import "./assets/css/root.css";

const Home = lazy(() => import('./pages/Home'));
const TechnologySelection = lazy(() => import('./pages/TechnologySelection'));
const ChooseDeveloper = lazy(() => import('./pages/ChooseDeveloper'));
const Summery = lazy(() => import('./pages/Summery'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage/RegisterPage'));
const Profile = lazy(() => import('./pages/Profile'));
const NewProduct = lazy(() => import('./pages/NewProduct'));
const AddnewProduct = lazy(() => import('./pages/AddnewProduct'));
const AddnftProduct = lazy(() => import('./pages/AddnftProduct'));
const PostRequest = lazy(() => import('./pages/PostRequest'));
const AIEnquiry = lazy(() => import('./pages/AIEnquiry'));
const GetEmailName = lazy(() => import('./pages/GetEmailName'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const EditProfile = lazy(() => import('./pages/Dashboards/EditProfile'));
const SetupCalendar = lazy(() => import('./pages/SetupCalendar'));
const RequestMeeting = lazy(() => import('./pages/RequestMeeting'));
const ServiceRequest = lazy(() => import('./pages/ServiceRequest'));
const Payment = lazy(() => import('./pages/Payment'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));
const AddReview = lazy(() => import('./pages/AddReview'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetLinkSuccess = lazy(() => import('./pages/ResetLinkSuccess'));
const SetNewPassword = lazy(() => import('./pages/SetNewPassword'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const DashboardProfile = lazy(() => import('./pages/Dashboards/DashboardProfile'));
const BankinfoDashboard = lazy(() => import('./pages/Dashboards/BankinfoDashboard'));
const TimeTracking = lazy(() => import('./pages/Dashboards/TimeTracking'));
const DeveloperContract = lazy(() => import('./pages/Dashboards/DeveloperContract'));
const Invoice = lazy(() => import('./pages/Dashboards/Invoice'));
const ProductOffering = lazy(() => import('./pages/ProductOffering'));
const Technology = lazy(() => import('./pages/Technology'));
const Catalogue = lazy(() => import('./pages/Catalogue'));
const SOPayment = lazy(() => import('./pages/SOPayment'));
const SOMPayment = lazy(() => import('./pages/SOMPayment'));
const SOMMPayment = lazy(() => import('./pages/SOMMPayment'));
const SOPaymentSuccess = lazy(() => import('./pages/SOPaymentSuccess'));
const EnterDetails = lazy(() => import('./pages/EnterDetails'));
const Vacation = lazy(() => import('./pages/Vacation'));
const ApiCustamize = lazy(() => import('./pages/ApiCustomize'));
const ApiResult = lazy(() => import('./pages/ApiResult'));
const ApiDashboard = lazy(() => import('./pages/Dashboards/ApiDashboard'));
const ApiDropdown = lazy(() => import('./pages/Dashboards/ApiDropdown'));
const Pricing = lazy(() => import('./pages/Pricing'));
const NewPricing = lazy(() => import('./pages/NewPricing'));
const Subscribe = lazy(() => import('./pages/Subscribe'));

function App() {
  const middlewareConfig = {
    interceptors: {
      request: [
        {
          success: function ({ getState, dispatch, getSourceAction }, req) {
            const token = getToken();
            if (token) {
              req.headers["Authorization"] = "Bearer " + token;
            }
            return req;
          },
        },
      ],
      response: [
        {
          success: function (
            { getState, dispatch, getSourceAction },
            response
          ) {
            return response;
          },
          error: function ({ getState, dispatch, getSourceAction }, error) {
            httpHandleError(error);
            return Promise.reject(error);
          },
        },
      ],
    },
  };
  const UnremotClient = axios.create({
    responseType: "json",
  });
  const createStoreWithMiddleware = applyMiddleware(
    axiosMiddleware(UnremotClient, middlewareConfig),
    thunk
    //logger
  )(createStore);
  const store = createStoreWithMiddleware(
    reducers,
    undefined,
    compose(
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({
          name: "Unremot",
        })
        : (f) => f
    )
  );

  return (
    <>
      <Suspense fallback={<div className="loading"><div className="loader-62"></div></div>}>
        <Provider store={store}>
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password-success" element={<ResetLinkSuccess />} />
              <Route path="/set-password" element={<SetNewPassword />} />
              <Route path="/technology" element={<TechnologySelection />} />
              <Route path="/developers" element={<ChooseDeveloper />} />
              <Route path="/summary" element={<Summery />} />
              <Route path="/category/:url" element={<Technology />} />
              <Route path="/my-profile" element={<Profile />} />

              <Route path="/catalogue/:holder/:url" element={<Catalogue />} />
              <Route path="/category/:holder/:url" element={<Catalogue />} />
              <Route path="/product-offering" element={<NewProduct />} />
              <Route path="/add-new-product" element={<AddnewProduct />} />
              <Route path="/add-nft-product" element={<AddnftProduct />} />
              <Route path="/post-request" element={<PostRequest />} />
              <Route path="/ai-enquiry" element={<AIEnquiry />} />
              <Route path="/your-address" element={<GetEmailName />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />

              <Route path="/setup-calendar" element={<SetupCalendar />} />
              <Route path="/request-meeting" element={<RequestMeeting />} />
              <Route path="/service-request/:url" element={<ServiceRequest />} />
              <Route path="/payment/:url" element={<Payment />} />
              <Route path="/payment-success/:projectId" element={<PaymentSuccess />} />
              <Route path="/add-review/:holder" element={< AddReview />} />

              <Route path="/dashboard/profile" element={< DashboardProfile />} />
              <Route path="/dashboard/edit-profile" element={<EditProfile />} />
              <Route path="/dashboard/bank-info" element={< BankinfoDashboard />} />
              <Route path="/dashboard/developer-contract" element={< DeveloperContract />} />
              <Route path="/dashboard/time-tracking" element={< TimeTracking />} />
              <Route path="/dashboard/invoice" element={< Invoice />} />
              <Route path="/dashboard/apidashboard" element={< ApiDashboard />} />
              <Route path="/dashboard/choose-api" element={< ApiDropdown />} />


              <Route path="/book-service-offering/:holder/:url/:mId" element={<SOMPayment />} />
              <Route path="/book-service-offering/:holder/:url/:mId/:smId" element={<SOMMPayment />} />
              <Route path="/book-service-offering/:holder/:url" element={<SOPayment />} />
              <Route path="/success/:projectId" element={<SOPaymentSuccess />} />

              <Route path="/:productSlug" element={<Profile />} />
              <Route path="/product-offering-new" element={< ProductOffering />} />
              <Route path="/enter-details" element={< EnterDetails />} />
              <Route path="/vacation" element={< Vacation />} />
              <Route path="/api-customize/:api/:id" element={< ApiCustamize />} />
              <Route path="/api/:id" element={< ApiResult />} />
              <Route path="/pricing/:holder/:url" element={< Pricing />} />
              <Route path="/pricing" element={< NewPricing />} />
              <Route path="/subscribe/:priceId" element={< Subscribe />} />


              {/* 404 route */}
              <Route path="/not-found" element={<NotFoundPage />} />

            </Routes>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </>
  );
}

export default App;
